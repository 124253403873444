import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classes from './Dashboard.module.css'
import Offers from './UI/Offers/Offers'

import { selectCurrentBusiness } from '../../../store/reducers/managedBusinessesSlice'
import {
  fetchDashboard,
  selectDashboard,
  selectDashboardIsObsolete,
  selectDashboardStatus,
} from '../../../store/reducers/dashboardSlice'
import { sub } from 'date-fns'
import { ROUTES } from '../../../submodules/naoo-web-components/Shared/constants'
import { useNavigate } from 'react-router-dom'

const Dashboard = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const [isTimeframeDropdownVisible, setTimeframeDropdownVisible] = useState(false)
  const [timeframeStart] = useState(
    sub(new Date(), {
      days: 30,
    })
  )
  // const [timeframeText, setTimeframeText] = useState('Last 30 days')
  const timeframeEnd = new Date() // now
  // const handleTimeframeClick = () => {
  //   setTimeframeDropdownVisible(!isTimeframeDropdownVisible)
  // }

  // get current business & dashboard data from redux store
  const currentBusiness = useSelector((state) => selectCurrentBusiness(state))

  const dashboardData = useSelector((state) => selectDashboard(state))
  const dashboardStatus = useSelector((state) => selectDashboardStatus(state))
  const dashboardIsObsolete = useSelector((state) => selectDashboardIsObsolete(state))
  // const dashboardError = useSelector((state) => selectDashboardError(state))
  // fetch dashboard if not fetched OR if current business has changed (but the dashboard has not been reloaded yet) OR if data is obsolete
  if (
    (currentBusiness && dashboardStatus === 'idle') ||
    (currentBusiness &&
      dashboardData &&
      dashboardData.businessId !== currentBusiness.id &&
      dashboardStatus !== 'loading') ||
    (currentBusiness && (dashboardStatus === 'idle' || dashboardStatus === 'succeeded') && dashboardIsObsolete === true)
  ) {
    dispatch(
      fetchDashboard({
        businessId: currentBusiness.id,
        timeframeStart: timeframeStart,
        timeframeEnd: timeframeEnd,
      })
    )
  }

  // const timeframeDropdown = isTimeframeDropdownVisible ? (
  //   <DashboardTimeframeDropdown
  //     onStartDateSelected={(startDate, newTimeframeText) => {
  //       setTimeframeStart(startDate)
  //       setTimeframeDropdownVisible(false)
  //       setTimeframeText(newTimeframeText)
  //       dispatch(
  //         fetchDashboard({
  //           businessId: currentBusiness.id,
  //           timeframeStart: startDate,
  //           timeframeEnd: timeframeEnd,
  //         })
  //       )
  //     }}
  //   />
  // ) : null

  const onCreateNewOffer = () => {
    navigate(ROUTES.ADMIN_DASHBOARD.OFFERS + '/create')
  }

  // section title
  const title = <span className={classes.Title}>Dashboard</span>
  // selected timeframe
  // const timeframe = (
  //   <div className={classes.Timeframe}>
  //     <span className={classes.TimeframeText} onClick={handleTimeframeClick}>
  //       {timeframeText}
  //     </span>
  //     <DropdownButton clicked={handleTimeframeClick} />
  //     {timeframeDropdown}
  //   </div>
  // )

  // const kpis = (
  //   <KPIs
  //     walkins={dashboardData ? dashboardData.kpis.walkins : null}
  //     pointsClaimed={dashboardData ? dashboardData.kpis.pointsClaimed : null}
  //     avgRating={dashboardData ? dashboardData.kpis.rating : null}
  //   />
  // )
  const offers = (
    <Offers
      onOfferUpdated={(completionHandler) => {
        // fetch dashboard
        dispatch(
          fetchDashboard({
            businessId: currentBusiness.id,
            timeframeStart: timeframeStart,
            timeframeEnd: timeframeEnd,
          })
        ).then(completionHandler())
      }}
      onOfferDeleted={(completionHandler) => {
        // fetch dashboard
        dispatch(
          fetchDashboard({
            businessId: currentBusiness.id,
            timeframeStart: timeframeStart,
            timeframeEnd: timeframeEnd,
          })
        ).then(completionHandler())
      }}
      createNewOfferClicked={onCreateNewOffer}
      offers={dashboardData && dashboardData.offerPerformances.length > 0 ? dashboardData.offerPerformances : null}
    />
  )
  return (
    <div className={classes.Dashboard}>
      {title}
      {/*{timeframe}*/}
      {/*{kpis}*/}
      {offers}
    </div>
  )
}

export default Dashboard
