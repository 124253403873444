import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createMediaPresignedUrl,
  deleteBusiness,
  getBusinessById,
  getFollowers,
  getMedia,
  mainCreateBusiness,
  mainGetBusinessCategories,
  mainUpdateBusiness,
} from '../../api/api'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'
import { searchParamsSelector } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'

export const fetchBusiness = createAsyncThunk('business/fetchBusiness', async (businessId) => {
  const response = await getBusinessById(businessId)
  return response.data
})

export const createBusiness = createAsyncThunk('business/createBusiness', async (business) => {
  if (business.logo) {
    const logo = business?.logo?.id ? business.logo : await sendImage(business.logo, createMediaPresignedUrl, getMedia)
    business.logoMediaId = logo.id
  }
  if (business.cover) {
    const cover = business?.cover?.id
      ? business.cover
      : await sendImage(business.cover, createMediaPresignedUrl, getMedia)
    business.coverMediaId = cover.id
  }
  business.businessCategoryIds = business.businessCategories?.map((category) => category.id)

  const response = await mainCreateBusiness({
    ...business,
    isActive: true,
  })
  return response.data
})

export const updateBusiness = createAsyncThunk('business/updateBusiness', async (business) => {
  if (business.logo) {
    const logo = business?.logo?.id ? business.logo : await sendImage(business.logo, createMediaPresignedUrl, getMedia)
    business.logoMediaId = logo.id
  }
  if (business.cover) {
    const cover = business?.cover?.id
      ? business.cover
      : await sendImage(business.cover, createMediaPresignedUrl, getMedia)
    business.coverMediaId = cover.id
  }
  business.businessCategoryIds = business.businessCategories?.map((category) => category.id)

  const response = await mainUpdateBusiness({
    ...business,
  })
  return response.data
})

export const addProfileMediaThunk = createAsyncThunk(
  'business/addProfileMediaThunk',
  async ({ businessId, urlList, mediaIds }) => {
    const mediaIdList = []
    for (const url of urlList) {
      const { mediaId } = await sendImage(url, createMediaPresignedUrl, getMedia)
      mediaIdList.push(mediaId)
    }
    if (mediaIds?.length) {
      mediaIdList.push(...mediaIds)
    }
    const response = await mainUpdateBusiness(
      {
        profileMediaIds: mediaIdList,
      },
      businessId
    )
    return response.data
  }
)

export const deleteProfileMediaThunk = createAsyncThunk(
  'business/deleteProfileMediaThunk',
  async ({ businessId, mediaId }, { getState }) => {
    const {
      business: { business: activeBusiness },
    } = getState()
    console.log(activeBusiness)
    const response = await mainUpdateBusiness(
      {
        profileMediaIds: activeBusiness?.profileMedia.filter((item) => item.id !== mediaId).map((item) => item.id),
      },
      businessId
    )
    return response.data
  }
)

export const fetchCategories = createAsyncThunk('business/fetchCategories', async () => {
  const response = await mainGetBusinessCategories()
  return response.data
})

export const deleteBusinessThunk = createAsyncThunk('business/deleteBusinessThunk', async (payload) => {
  await deleteBusiness(payload)
})

export const getBusinessFollowersThunk = createAsyncThunk(
  'business/getBusinessFollowers',
  async (payload, { getState }) => {
    const { startTime, businessId } = searchParamsSelector(getState())
    console.log(businessId)
    const response = await getFollowers({
      startTime,
      followingBusinessWithId: businessId,
    })
    return response.data
  }
)

export const getBusinessFollowersByNexToken = createAsyncThunk(
  'business/getBusinessFollowersByNexToken',
  async (payload, { getState }) => {
    const { startTime, businessId } = searchParamsSelector(getState())

    const response = await getFollowers({
      startTime,
      followingBusinessWithId: businessId,
      nextToken: payload.nextToken,
    })
    return response.data
  }
)

const initialState = {
  business: {
    name: '',
    description: '',
    businessCategories: [],
    website: '',
    phone: '',
    email: '',
    address: '',
    coordinates: [],
    logo: '',
    cover: '',
    logoDimensions: [],
    profileMedia: [],
  },
  categories: [],
  followers: [],
  followersNextToken: '',
  followersFetching: false,
  status: '',
}

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.business.name = action.payload
    },
    addCategory: (state, action) => {
      //delete action.payload.isSelected
      state.business.businessCategories.push(action.payload)
      state.categories.find((item) => item.id === action.payload.id).isSelected = true
    },
    removeCategory: (state, action) => {
      state.business.businessCategories = state.business.businessCategories.filter(
        (item) => item.id !== action.payload.id
      )
      state.categories.find((item) => item.id === action.payload.id).isSelected = false
    },
    setDescription: (state, action) => {
      state.business.description = action.payload
    },
    setVerify: (state, action) => {
      state.business.verify = action.payload
    },
    setWebsite: (state, action) => {
      state.business.website = action.payload
    },
    setPhone: (state, action) => {
      state.business.phone = action.payload
    },
    setEmail: (state, action) => {
      state.business.email = action.payload
    },
    setLogo: (state, action) => {
      state.business.logo = action.payload
    },
    setLogoDimensions: (state, action) => {
      state.business.logoDimensions = action.payload
    },
    setCover: (state, action) => {
      state.business.cover = action.payload
    },
    setProfileMedia: (state, action) => {
      state.business.profileMedia = action.payload
    },
    addProfileMedia: (state, action) => {
      action.payload.forEach((item) => {
        state.business.profileMedia.push(item)
      })
    },
    deleteProfileMedia: (state, action) => {
      state.business.profileMedia = state.business.profileMedia.filter((item) => {
        if (action.payload?.media) {
          return item.id !== action.payload.id
        }
        return item !== action.payload
      })
    },
    setAddress: (state, action) => {
      state.business.address = action.payload
    },
    setCoordinates: (state, action) => {
      state.business.coordinates = action.payload
    },
    setHours: (state, action) => {
      state.business.customHours = action.payload.customHours
    },
    clearBusiness: (state) => {
      state.business = {
        name: '',
        description: '',
        businessCategories: [],
        website: '',
        phone: '',
        email: '',
        address: '',
        coordinates: [],
        logo: '',
        profileMedia: [],
      }
    },
    setFetchingFollowers: (state, action) => {
      state.followersFetching = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusiness.fulfilled, (state, action) => {
      state.business = {
        ...action.payload,
        customHours: action.payload.hours,
      }
      if (!action.payload.businessCategories) {
        state.business.businessCategories = []
      }
      if (action.payload && state.categories.length !== 0 && action.payload.businessCategories) {
        action.payload.businessCategories?.forEach((item) => {
          const category = state.categories.find((category) => category.id === item.id)
          category.isSelected = true
        })
      }
      if (!action.payload.profileMedia) {
        state.business.profileMedia = []
      }
    })
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload.map((item) => {
        return {
          ...item,
          isSelected: false,
        }
      })
    })
    builder.addCase(updateBusiness.fulfilled, (state, action) => {
      state.business = action.payload
      state.business.customHours = action.payload.hours
    })
    builder.addCase(addProfileMediaThunk.fulfilled, (state, action) => {
      state.business = action.payload
    })
    builder.addCase(deleteProfileMediaThunk.fulfilled, (state, action) => {
      state.business = action.payload
    })
    builder.addCase(getBusinessFollowersThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getBusinessFollowersThunk.fulfilled, (state, action) => {
      state.status = 'successfully'
      state.followers = action.payload?.users
      state.followersNextToken = action.payload?.nextToken
    })
    builder.addCase(getBusinessFollowersThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(getBusinessFollowersByNexToken.fulfilled, (state, action) => {
      state.status = 'successfully'
      if (action?.payload?.follows) {
        state.followers.push(...action.payload.users)
      }
      state.followersNextToken = action.payload.nextToken
      state.followersFetching = false
    })
  },
})

export default businessSlice.reducer

export const {
  setName,
  setWebsite,
  setPhone,
  setEmail,
  setDescription,
  addCategory,
  removeCategory,
  setVerify,
  setLogo,
  setLogoDimensions,
  setCover,
  setProfileMedia,
  addProfileMedia,
  deleteProfileMedia,
  setAddress,
  setCoordinates,
  setHours,
  clearBusiness,
  setFetchingFollowers,
} = businessSlice.actions

export const selectBusiness = (state) => state.business.business
export const selectBusinessStatus = (state) => state.business.status
export const selectCategories = (state) => state.business.categories
export const selectFollowers = (state) => state.business.followers
export const selectFetchingFollowers = (state) => state.business.followersFetching
export const selectFollowersNextToken = (state) => state.business.followersNextToken
