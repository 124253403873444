import React, { useContext } from 'react'
import Sidebar from '../../../submodules/naoo-web-components/Components/Sidebar/Sidebar'
import SidebarItem from '../../../submodules/naoo-web-components/Components/Sidebar/UI/SidebarItem'
import SidebarItems from '../../../submodules/naoo-web-components/Components/Sidebar/UI/SidebarItems'
import { ROUTES } from '../../../submodules/naoo-web-components/Shared/constants'
import classes from './BusinessSidebar.module.scss'
import Logo from '../../shared/UI/assets/naoo-business-logo.svg'
import { withAnalytics } from '../../shared/utility/withAnalytics'
import {
  MODULE_BUSINESS_PROFILE_ENABLED,
  MODULE_FOLLOWERS_ENABLED,
  MODULE_OFFERS_ENABLED,
  MODULE_POSTS_ENABLED,
  MODULE_SUBSCRIPTIONS_ENABLED,
  RemoteConfigContext,
} from '../../shared/contexts/remote-config.context'
import { getBoolean } from 'firebase/remote-config'

const BusinessSidebar = ({ isOffset, disabled, children }) => {
  const remoteConfig = useContext(RemoteConfigContext)
  const handlePostsClick = withAnalytics('dashboards.business.sidebar.posts_btn_clicked')
  const handleOffersClick = withAnalytics('dashboards.business.sidebar.offers_btn_clicked')
  const handleFollowersClick = withAnalytics('dashboards.business.sidebar.followers_btn_clicked')
  const handleBusinessProfileClick = withAnalytics('dashboards.business.sidebar.business_profile_btn_clicked')
  const handleDashboardClick = withAnalytics('dashboards.business.sidebar.dashboard_btn_clicked')
  const handleSubscriptionClick = withAnalytics('dashboards.business.sidebar.subscription_btn_clicked')

  return (
    <Sidebar isOffset={isOffset}>
      <img className={classes.Logo} src={Logo} alt='' />
      {isOffset && <div className={classes.SidebarOffset} />}
      {children}
      <SidebarItems>
        <SidebarItem
          disabled={disabled}
          title='Dashboard'
          to={ROUTES.BUSINESS_DASHBOARD.DASHBOARD}
          onClick={handleDashboardClick}
        />
        {getBoolean(remoteConfig, MODULE_BUSINESS_PROFILE_ENABLED) && (
          <SidebarItem
            disabled={disabled}
            title='Manage Profile'
            to={ROUTES.BUSINESS_DASHBOARD.BUSINESS}
            onClick={handleBusinessProfileClick}
          />
        )}
        {getBoolean(remoteConfig, MODULE_SUBSCRIPTIONS_ENABLED) && (
          <SidebarItem
            disabled={disabled}
            title='Subscription'
            to={ROUTES.BUSINESS_DASHBOARD.SUBSCRIPTION}
            onClick={handleSubscriptionClick}
          />
        )}
        {getBoolean(remoteConfig, MODULE_POSTS_ENABLED) && (
          <SidebarItem disabled={disabled} title='Posts' to={ROUTES.ADMIN_DASHBOARD.POSTS} onClick={handlePostsClick} />
        )}
        {getBoolean(remoteConfig, MODULE_FOLLOWERS_ENABLED) && (
          <SidebarItem disabled={disabled} title='Followers' to={'/followers'} onClick={handleFollowersClick} />
        )}
        {/*{getBoolean(remoteConfig, MODULE_CHAT_ENABLED) && <SidebarItem disabled={disabled} title='Chat' to={'/chat'} />}*/}
        {getBoolean(remoteConfig, MODULE_OFFERS_ENABLED) && (
          <SidebarItem
            disabled={disabled}
            title='Offers'
            to={ROUTES.ADMIN_DASHBOARD.OFFERS}
            onClick={handleOffersClick}
          />
        )}
        <SidebarItem disabled={disabled} title='Logout' to={ROUTES.AUTH.LOGOUT} />
      </SidebarItems>
    </Sidebar>
  )
}

export default BusinessSidebar
