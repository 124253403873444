import BusinessSidebar from '../Sidebar/BusinessSidebar'
import Dashboard from './Dashboard'

import classes from './Dashboard.module.css'
import Topbar from '../Topbar/Topbar'
import React from 'react'

const DashboardContainer = () => {
  return (
    <div className={classes.DashboardContainer}>
      <BusinessSidebar>
        <div style={{ height: 50 }} />
      </BusinessSidebar>
      <div className={classes.Main}>
        <Topbar />
        <Dashboard />
      </div>
    </div>
  )
}

export default DashboardContainer
